/* Glossary.css */

.glossary-title {
    font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
    .Container .glossary-title {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .stat-explanation-paper {
        padding: 16px !important;
    }

        .stat-explanation-paper h3 {
            font-size: 1.2rem !important;
        }

    .stat-formula {
        font-size: 0.8rem !important;
        padding: 8px !important;
    }

    /* Make tabs more compact on mobile */
    .MuiTab-root {
        min-width: 50px !important;
        padding: 8px !important;
    }
}

/* To match your site's animations */
.stat-explanation-paper {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

    .stat-explanation-paper:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1) !important;
    }

/* Custom scrollbar for tabs */
.MuiTabs-scrollButtons {
    color: #65FBD2 !important;
}

/* Filter count badge */
.filter-count {
    opacity: 0.8;
    font-size: 0.8rem;
    font-weight: normal;
}
